import React, { useEffect, useState } from "react";
import "./CheckoutProcess.css";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Breadcrumb, Form } from "react-bootstrap";
import AddressDetail from "./AddressDetail";
import PaymentOption from "./PaymentOption";
import ConfirmOrder from "./ConfirmOrder";
import { useForm } from "react-hook-form";

const CheckoutProcess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [current, setCurrent] = useState(1);

  const stepLabels = [
    "1. Address Details",
    "2. Payment Options",
    "3. Confirm Order",
  ]; // Define custom labels for each step

  const stepLabelsBread = [
    "Address Details",
    " Payment Options",
    " Confirm Order",
  ]; // Define custom labels for each step

  const handleNext = () => {
    if (current < 3) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Account", link: "" },
    { text: "Cart", link: "/cart" },
    { text: "Checkout", link: "", className: "active" },
  ]);
  const getBreadcrumbItems = () => {
    const items = [
      // <Breadcrumb.Item key="home">
      //   <Link to={"/"}>Home</Link>
      // </Breadcrumb.Item>,
      // <Breadcrumb.Item key="cart">
      //   <Link to={"/product-cart"}>Cart</Link>
      // </Breadcrumb.Item>,
    ];

    for (let i = 1; i <= current; i++) {
      items.push(
        <Breadcrumb.Item key={i} active={i === current}>
          {stepLabelsBread[i - 1]}
        </Breadcrumb.Item>
      );
    }

    return items;
  };

  // ----------------------------------------------------------------

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      payment_mode: "online",
      coupon_applyed: false,
    },
  });
  // ----------------------------------------------------------------

  return (
    <>
      {/* checkout-process-step-form start  */}
      <section className="checkout-process-step-form Product_Cart">
        <div className="container">
          <div className="heading_holder">
            {/* <Breadcrumb>{getBreadcrumbItems()}</Breadcrumb> */}
            <Breadcrumbs items={breadcrumbItems} />
          </div>

          <Form>
            <div className="progress-bar-container">
              <div className="step-row">
                {stepLabels.map((icon, index) => (
                  <div
                    key={index + 1}
                    className={`step-container ${index + 1 === current ? "active" : ""
                      }`}
                  >
                    <div>
                      <span className="step-text small-text">
                        {stepLabels[index]}
                      </span>
                    </div>
                    <div className="circle-center">
                      <div
                        className={`circle ${isStepCompleted(index + 1) ? "completed" : ""
                          }`}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="steps">
              {current === 1 && (
                <AddressDetail
                  handleNext={handleNext}
                  getValues={getValues}
                  setValue={setValue}
                  reset={reset}
                  register={register}
                  control={control}
                  setError={setError}
                  errors={errors}
                />
              )}
              {current === 2 && (
                <PaymentOption
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  getValues={getValues}
                  setValue={setValue}
                  reset={reset}
                  register={register}
                  control={control}
                  setError={setError}
                  errors={errors}
                />
              )}
              {current === 3 && (
                <ConfirmOrder
                  handlePrevious={handlePrevious}
                  getValues={getValues}
                  setValue={setValue}
                  reset={reset}
                  register={register}
                  control={control}
                  setError={setError}
                  errors={errors}
                  clearErrors={clearErrors}
                />
              )}
            </div>
          </Form>
        </div>
      </section>
      {/* checkout-process-step-form end  */}
    </>
  );
};

export default CheckoutProcess;
