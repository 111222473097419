import React, { useEffect, useState } from "react";
import "./EditAddress.css";
import Button_Common from "../../Button_Common/Button_Common";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";
import "./EditAddress.css";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { EditAddress, GetAddress } from "../../../../utils/apis/address";
import Select from "react-select";
import classNames from "classnames";
import { getData, Select2Data } from "../../../../utils/api";
import debounce from "lodash.debounce";
const EditAddressComponent = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    let timer;
    if (modalShow) {
      timer = setTimeout(() => {
        setModalShow(false);
      }, 2000);
    }

    return () => clearTimeout(timer); // Cleanup the timer
  }, [modalShow]); // Dependency array

  // ------------------------------------------------------------------------------------------------
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const DataToSend = new FormData();
      DataToSend.append("add_type", data?.add_type?.value);
      DataToSend.append("address", data?.address);
      DataToSend.append("landmark", data?.landmark);
      // DataToSend.append("zip_code", data?.zip_code);
      DataToSend.append("contact_no", data?.contact_no);
      DataToSend.append("pincode_id", data?.pincode_id?.value);

      const res = await EditAddress(props.show, DataToSend);
      reset();
      props.getAddressData();
      setModalShow(true);
      props.onHide();
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async (id) => {
    const res = await GetAddress(id);
    reset(res.data);
  };

  const [pincodes, setPincodes] = useState([]);
  const [searcTerm, setSearchTerm] = useState("");
  const GetAllPincodes = async (searcTerm) => {
    console.log("searcTermsearcTermsearcTerm", searcTerm);

    const search = searcTerm || "";
    const response = await getData(`/admin/allpincodes?search=${search || ""}`);

    if (response?.success) {
      setPincodes(await Select2Data(response?.data, "pincode_id"));
    }
  };

  const debouncedGetPincodes = debounce(async (search) => {
    await GetAllPincodes(search);
  }, 500);

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
    debouncedGetPincodes(inputValue); // Call the API after debouncing
  };

  useEffect(() => {
    GetAllPincodes();
  }, []);

  useEffect(() => {
    GetEditData(props.show);
  }, [props.show]);
  return (
    <>
      <section className="EditAddress">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="EditAddress"
        >
          {/* <Modal.Body> */}
          <div className="Modal-holder">
            <div className="log_in_form">
              <div className="login-box-button">
                <p>Edit Address</p>
              </div>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">Address Type *</Form.Label>
                  <Controller
                    name="add_type"
                    {...register("add_type", {
                      required: "Select Address Type",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.add_type ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={props.options}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">
                    Flat no./House/bldg*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Flat no./House/bldg"
                    className={classNames("", {
                      "is-invalid": errors?.address,
                    })}
                    {...register("address", {
                      required: "Flat no./House/bldg is required",
                    })}
                  />{" "}
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">
                    Colony/street/landmark
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="landmark"
                    placeholder="Colony/street/landmark"
                    className={classNames("", {
                      "is-invalid": errors?.landmark,
                    })}
                    {...register("landmark", {
                      required: "Colony/street/landmark is required",
                    })}
                  />
                </Form.Group>

                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">Zip code*</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    placeholder="Zip code"
                    className={classNames("", {
                      "is-invalid": errors?.zip_code,
                    })}
                    {...register("zip_code", {
                      required: "Zip code is required",
                      pattern: {
                        value: /^\d{6}$/,
                        message: "Please enter a valid 6-digit",
                      },
                    })}
                  />
                </Form.Group> */}

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">Pincode*</Form.Label>
                  <Controller
                    name="pincode_id"
                    {...register("pincode_id", {
                      required: "Choose Pincode",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.pincode_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={pincodes}
                        onInputChange={handleInputChange}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">Mobile number*</Form.Label>
                  <Form.Control
                    type="number"
                    name="contact_no"
                    placeholder="Mobile number"
                    className={classNames("", {
                      "is-invalid": errors?.contact_no,
                    })}
                    {...register("contact_no", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    })}
                  />
                </Form.Group>
              </Form>
              <div className="Button-Main">
                <Button_Common
                  text="Cancel"
                  onClick={() => props.onHide()}
                  className="cancel"
                />
                <Button_Common
                  text="Submit"
                  className="submit orange"
                  // onClick={() => {
                  //   setModalShow(true);
                  //   props.onHide();
                  // }}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
          {/* </Modal.Body> */}
        </Modal>

        <Bottom_Successfull_Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          icon={faCircleCheck}
          text={"Address Changed Successfully!"}
        />
      </section>
    </>
  );
};

export default EditAddressComponent;
